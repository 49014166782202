import { useEffect, useRef } from 'react'
import '@/styles/tailwind.css'
import 'focus-visible'
import { Prata } from '@next/font/google'
import { DM_Sans } from '@next/font/google'
import { Raleway } from '@next/font/google'

function usePrevious(value) {
  let ref = useRef()

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

const prata = Prata({
  weight: ['400'],
  subsets: ['latin'],
  variable: '--font-prata',
})

const raleway = Raleway({
  weight: ['300', '400', '500'],
  subsets: ['latin'],
  variable: '--font-raleway',
})

const dmsans = DM_Sans({
  weight: ['400', '500', '700'],
  subsets: ['latin'],
  variable: '--font-dmsans',
})

// const prata = Prata({ weight: ['400'], subsets: ['latin'] })
// const dmSans = DM_Sans({ weight: ['400', '500', '700'], subsets: ['latin'] })

export default function App({ Component, pageProps, router }) {
  let previousPathname = usePrevious(router.pathname)

  return (
    <>
      {/* <div className="fixed inset-0 flex justify-center sm:px-8">
        <div className="flex w-full max-w-7xl lg:px-8">
          <div className="w-full bg-dammanBg  dark:bg-dammanBgDark dark:ring-zinc-300/20" />
        </div>
      </div> */}
      {/* prettier-ignore */}
      {/* <div className="prose sm:prose-sm lg:prose-lg relative dark:prose-invert"> */}
      {/* <Component previousPathname={previousPathname} {...pageProps} /> */}
      <main
        className={`${prata.variable} ${dmsans.variable} ${raleway.variable}`}
      >
        <Component previousPathname={previousPathname} {...pageProps} />
      </main>
      {/* </div> */}
    </>
  )
}
